import React from 'react';
import { Table, Switch } from 'antd';
import { Link } from 'react-router-dom';
import { FirestoreContext } from '../FirestoreContext';
import { CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { EditableRow } from './EditableRow';
import { EditableCell } from './EditableCell';
import { mapEditableCols } from './mapEditableCols';
import ReactMarkdown from 'react-markdown';

function tableCollectionRef(db: any) {
  return db
    .collection('databases')
    .doc('sql_server#vxr_bms')
    .collection('views');
}

const onDoubtChange = (db: any, record: any) => (value: any) => {
  const updatedObject = {
    last_edit_uid: (window as any).firebase.auth().currentUser.uid,
    doubt: value,
    ...(value
      ? {
          read: false,
          write: false,
          maintain: false,
        }
      : {}),
  };

  tableCollectionRef(db)
    .doc(record.key)
    .set(updatedObject, { merge: true });
};

const onMaintainChange = (db: any, record: any) => (value: any) => {
  const updatedObject = {
    last_edit_uid: (window as any).firebase.auth().currentUser.uid,
    maintain: value,
    ...(value
      ? {
          read: true,
          write: true,
          doubt: false,
          trash: false,
        }
      : {}),
  };

  tableCollectionRef(db)
    .doc(record.key)
    .set(updatedObject, { merge: true });
};

const onReadChange = (db: any, record: any) => (value: any) => {
  const updatedObject = {
    last_edit_uid: (window as any).firebase.auth().currentUser.uid,
    read: value,
    ...(value
      ? {
          doubt: false,
          trash: false,
        }
      : {}),
  };

  tableCollectionRef(db)
    .doc(record.key)
    .set(updatedObject, { merge: true });
};

const onWriteChange = (db: any, record: any) => (value: any) => {
  const updatedObject = {
    last_edit_uid: (window as any).firebase.auth().currentUser.uid,
    write: value,
    ...(value
      ? {
          doubt: false,
          trash: false,
        }
      : {}),
  };

  tableCollectionRef(db)
    .doc(record.key)
    .set(updatedObject, { merge: true });
};

const onTrashChange = (db: any, record: any) => (value: any) => {
  const updatedObject = {
    last_edit_uid: (window as any).firebase.auth().currentUser.uid,
    trash: value,
    ...(value
      ? {
          read: false,
          write: false,
          maintain: false,
        }
      : {}),
  };

  tableCollectionRef(db)
    .doc(record.key)
    .set(updatedObject, { merge: true });
};

const makeColumns = (db: any) => [
  {
    title: 'Name',
    render: (r: any) => <Link to={`views/${r.key}`}>{r.name}</Link>,
    sorter: (a: any, b: any) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0) as any,
    // sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Description',
    dataIndex: 'description',
    editable: true,
    render: (description: string) => (
      <div style={{display: 'flex'}}>
        <div>
          <ReactMarkdown source={description} />
        </div>
        <EditOutlined style={{
            marginLeft: '5px',
            position: 'absolute',
            top: '22px',
            right: '22px',
          }}
        />
      </div>
    )
  },
  {
    title: 'Read?',
    dataIndex: 'read',
    render: (value: boolean, record: any) => (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!value}
        onChange={onReadChange(db, record)}
      />
    ),
  },
  {
    title: 'Write?',
    dataIndex: 'write',
    render: (value: boolean, record: any) => (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!value}
        onChange={onWriteChange(db, record)}
      />
    ),
  },
  {
    title: 'Maintain?',
    dataIndex: 'maintain',
    render: (value: boolean, record: any) => (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!value}
        onChange={onMaintainChange(db, record)}
      />
    ),
  },
  {
    title: 'Doubt?',
    dataIndex: 'doubt',
    render: (value: boolean, record: any) => (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!value}
        onChange={onDoubtChange(db, record)}
      />
    ),
  },
  {
    title: 'Trash?',
    dataIndex: 'trash',
    render: (value: boolean, record: any) => (
      <Switch
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        checked={!!value}
        onChange={onTrashChange(db, record)}
      />
    ),
  },
  {
    title: 'Last updated',
    dataIndex: 'last_updated',
    render: (dt: Date) => dt.toLocaleString(),
    sorter: (a: any, b: any) => (a.last_updated.getTime() - b.last_updated.getTime()) as any,
  },
];

const rowSelection: any = {
  onChange: (selectedRowKeys: any, selectedRows: any) => {
    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
  },
  getCheckboxProps: (record: any) => ({
    disabled: record.name === 'Disabled User', // Column configuration not to be checked
    name: record.name,
  }),
};

class DatabaseTableListInner extends React.Component<{ firestore: any }, { data: any; loading: boolean }> {
  firestore: any;

  state = {
    data: [],
    loading: true,
  };

  componentDidMount() {
    const db = this.props.firestore;

    function parseTableData(id: string, data: any) {
      return {
        key: id,
        name: data.name,
        definition: data.view_definition,
        description: data.description || 'Edit',
        read: data.read === undefined ? true : data.read,
        write: data.write === undefined ? true : data.write,
        maintain: data.maintain === undefined ? true : data.maintain,
        doubt: data.doubt === undefined ? false : data.doubt,
        trash: data.trash === undefined ? false : data.trash,
        last_updated: data.last_updated ? new Date(data.last_updated.seconds * 1000) : new Date(0),
      };
    }

    db.collection('databases')
      .doc('sql_server#vxr_bms')
      .collection('views')
      .onSnapshot((querySnapshot: any) => {
        const data: any[] = [];
        querySnapshot.forEach((doc: any) => {
          data.push(parseTableData(doc.id, doc.data()));
        });
        this.setState({ data: data, loading: false });
      });
  }

  render() {
    const components = {
      body: {
        row: EditableRow,
        cell: EditableCell,
      },
    };

    const db = this.props.firestore;
    const columns = mapEditableCols(makeColumns(db), (record: any) => {
      tableCollectionRef(db).
      doc(record.key).
      set({
        last_edit_uid: (window as any).firebase.auth().currentUser.uid,
        description: record.description
      }, {merge: true});
    });

    return (
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        loading={this.state.loading}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={this.state.data}
      />
    );
  }
}

export class DatabaseTableViewList extends React.Component {
  render() {
    return (
      <FirestoreContext.Consumer>
        {firestore => <DatabaseTableListInner firestore={firestore} />}
      </FirestoreContext.Consumer>
    );
  }
}
