import React, { useState } from 'react';
import { Table, Radio, Divider } from 'antd';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';
import { FirestoreContext } from '../FirestoreContext';
import { useRouteMatch, useLocation } from "react-router-dom";
import { EditableRow } from './EditableRow';
import { EditableCell } from './EditableCell';
import { mapEditableCols } from './mapEditableCols';
import ReactMarkdown from 'react-markdown';

function fieldCollectionRef(db: any, tableId: string) {
  return db.
    collection('databases').
    doc('sql_server#vxr_bms').
    collection('tables').
    doc(tableId).
    collection('fields')
}

export const DatabaseTableInner = ({firestore: db}: any) => {
  const onDoubtChange = (record: any) => (value: any) => {
    const updatedObject = {
      last_edit_uid: (window as any).firebase.auth().currentUser.uid,
      doubt: value,
      ...(value ? {
        read: false,
        write: false,
        maintain: false,
      } : {})
    }

    fieldCollectionRef(db, tableId).
      doc(record.key).
      set(updatedObject, {merge: true});
  };

  const onMaintainChange = (record: any) => (value: any) => {
    const updatedObject = {
      last_edit_uid: (window as any).firebase.auth().currentUser.uid,
      maintain: value,
      ...(value ? {
        read: true,
        write: true,
        doubt: false,
        trash: false,
      } : {})
    }

    fieldCollectionRef(db, tableId).
      doc(record.key).
      set(updatedObject, {merge: true});
  };

  const onReadChange = (record: any) => (value: any) => {
    const updatedObject = {
      last_edit_uid: (window as any).firebase.auth().currentUser.uid,
      read: value,
      ...(value ? {
        doubt: false,
        trash: false,
      } : {})
    }

    fieldCollectionRef(db, tableId).
      doc(record.key).
      set(updatedObject, {merge: true});
  };

  const onWriteChange = (record: any) => (value: any) => {
    const updatedObject = {
      last_edit_uid: (window as any).firebase.auth().currentUser.uid,
      write: value,
      ...(value ? {
        doubt: false,
        trash: false,
      } : {})
    }

    fieldCollectionRef(db, tableId).
      doc(record.key).
      set(updatedObject, {merge: true});
  };

  const onTrashChange = (record: any) => (value: any) => {
    const updatedObject = {
      last_edit_uid: (window as any).firebase.auth().currentUser.uid,
      trash: value,
      ...(value ? {
        read: false,
        write: false,
        maintain: false,
      } : {})
    }

    fieldCollectionRef(db, tableId).
      doc(record.key).
      set(updatedObject, {merge: true});
  };

  const columns = [
    // {
    //   title: 'Ordinal Position',
    //   dataIndex: 'ordinal_position',
    // },
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Data Type',
      dataIndex: 'data_type',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      editable: true,
      render: (description: string) => (
        <div style={{display: 'flex'}}>
          <div>
            <ReactMarkdown source={description} />
          </div>
          <EditOutlined style={{
              marginLeft: '5px',
              position: 'absolute',
              top: '22px',
              right: '22px',
            }}
          />
        </div>
      )
    },
    {
      title: 'Numeric Precision',
      dataIndex: 'numeric_precision',
    },
    {
      title: 'nullable?',
      dataIndex: 'is_nullable',
      render: (b: boolean) => b ? 'True' : 'False',
    },
    {
      title: 'Read?',
      dataIndex: 'read',
      render: (value: boolean, record: any) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!value}
          onChange={onReadChange(record)}
        />
      ),
    },
    {
      title: 'Write?',
      dataIndex: 'write',
      render: (value: boolean, record: any) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!value}
          onChange={onWriteChange(record)}
        />
      ),
    },
    {
      title: 'Maintain?',
      dataIndex: 'maintain',
      render: (value: boolean, record: any) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!value}
          onChange={onMaintainChange(record)}
        />
      ),
    },
    {
      title: 'Doubt?',
      dataIndex: 'doubt',
      render: (value: boolean, record: any) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!value}
          onChange={onDoubtChange(record)}
        />
      ),
    },
    {
      title: 'Trash?',
      dataIndex: 'trash',
      render: (value: boolean, record: any) => (
        <Switch
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          checked={!!value}
          onChange={onTrashChange(record)}
        />
      ),
    },
    {
      title: 'Last updated',
      dataIndex: 'last_updated',
      render: (dt: Date) => dt.toLocaleString(),
    },
  ];

  // rowSelection object indicates the need for row selection
  const rowSelection: any = {
    onChange: (selectedRowKeys: any, selectedRows: any) => {
      console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
    },
    getCheckboxProps: (record: any) => ({
      disabled: record.name === 'Disabled User', // Column configuration not to be checked
      name: record.name,
    }),
  };

  let match = useRouteMatch("/tables/:tableId") as any;

  const tableId = match?.params?.tableId;
  let location = useLocation();

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([] as any)

  React.useEffect(() => {
    db.
      collection('databases').
      doc('sql_server#vxr_bms').
      collection('tables').
      doc(tableId).
      collection('fields').
      onSnapshot((queryResult: any) => {
        const parsedData: any[] = [];
        queryResult.forEach((snapshot: any) => {
          const snData = snapshot.data();
          parsedData.push({
            key: snapshot.id,
            character_maximum_length: snData.character_maximum_length,
            data_type: snData.data_type,
            description: snData.description || 'Edit',
            is_nullable: snData.is_nullable,
            last_updated: new Date(snData.last_updated.seconds * 1000),
            name: snData.name,
            numeric_precision: snData.numeric_precision,
            ordinal_position: snData.ordinal_position,
            read: snData.read === undefined ? true : snData.read,
            write: snData.write === undefined ? true : snData.write,
            maintain: snData.maintain === undefined ? true : snData.maintain,
            doubt: snData.doubt === undefined ? false : snData.doubt,
            trash: snData.trash === undefined ? false : snData.trash,
          })
        });
        parsedData.sort((a, b) => a.ordinal_position - b.ordinal_position);
        setLoading(false);
        setData(parsedData);
      })
  }, [location]);

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns2 = mapEditableCols(columns, (record: any) => {
    // console.log
    fieldCollectionRef(db, tableId).
      doc(record.key).
      set({
        last_edit_uid: (window as any).firebase.auth().currentUser.uid,
        description: record.description
      }, {merge: true});
  });

  return (
    <Table
      rowClassName={() => 'editable-row'}
      components={components}
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns2}
      loading={loading}
      dataSource={data}
      pagination={false}
    />
  );
};

export const DatabaseTable = () => (
  <FirestoreContext.Consumer>
    {firestore => <DatabaseTableInner firestore={firestore} />}
  </FirestoreContext.Consumer>
);