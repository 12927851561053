import React, { useContext, useState, useEffect, useRef } from 'react';
import { Form } from 'antd';
import { Item } from './EditableTable';
import { EditableContext } from "./EditableContext";
import TextArea from 'antd/lib/input/TextArea';

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex: string;
  record: Item;
  handleSave: (record: Item) => void;
}

export const EditableCell: React.FC<EditableCellProps> = ({ title, editable, children, dataIndex, record, handleSave, ...restProps }: any) => {
  const [editing, setEditing] = useState(false);
  const inputRef: any = useRef();
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };
  const save = async (e: any) => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({ ...record, ...values });
    }
    catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (<Form.Item style={{ margin: 0 }} name={dataIndex} rules={[
      {
        required: true,
        message: `${title} is required.`,
      },
    ]}>
      <TextArea autoSize style={{width: '100%'}} ref={inputRef as any} onBlur={save} />
    </Form.Item>) : (<div className="editable-cell-value-wrap" style={{ paddingRight: 24 }} onClick={toggleEdit}>
      {children}
    </div>);
  }
  return <td {...restProps}>{childNode}</td>;
};
