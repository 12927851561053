import * as firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';

import React, { Component, useEffect, useState, useReducer } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { Layout, Menu, Button } from 'antd';
import { Result } from 'antd';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  BorderlessTableOutlined,
  DesktopOutlined,
  TableOutlined,
  SmileOutlined,
} from '@ant-design/icons';

import { DatabaseTable } from './components/DatabaseTable';
import { DatabaseTableList } from './components/DatabaseTableList';
import { FirestoreContext } from './FirestoreContext';
import { EditableTable } from './components/EditableTable';
import './App.css';
import { DatabaseTableViewList } from './components/DatabaseTableViewList';
import { DatabaseView } from './components/DatabaseView';
import { Dashboard } from './components/Dashboard';
import { AuthContext } from './AuthContext';

const firebaseConfig = {
  apiKey: 'AIzaSyDEqOk3_Ag0vslMuMOiubaWPH4u_Tzmd-8',
  authDomain: 'vexdata-9a7bd.firebaseapp.com',
  databaseURL: 'https://vexdata-9a7bd.firebaseio.com',
  projectId: 'vexdata-9a7bd',
  storageBucket: 'vexdata-9a7bd.appspot.com',
  messagingSenderId: '627836321052',
  appId: '1:627836321052:web:7aa967500e9c8c86424ed9',
  measurementId: 'G-NZ6HSDY8SS',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
window.firebase = firebase;

function login() {
  // Log the user in via Twitter
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
  provider.setCustomParameters({ hd: 'vexere.com' });
  firebase
    .auth()
    .setPersistence(firebase.auth.Auth.Persistence.SESSION)
    .then(() => {
      firebase
        .auth()
        .signInWithPopup(provider)
        .catch(function(error) {
          console.log('Error authenticating user:', error);
        });
    });
}

const Login = () => (
  <Layout style={{ minHeight: '100vh' }}>
    <Content
      style={{
        margin: '24px 16px',
        padding: 24,
        background: '#fff',
        minHeight: 1280,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Result
        title="Login"
        icon={<SmileOutlined />}
        subTitle="Please login with vexere google email first!"
        extra={
          <Button onClick={login} type="primary" size={'large'} style={{ marginLeft: '10px' }}>
            Login with Google
          </Button>
        }
      />
    </Content>
  </Layout>
);

const Auth: React.FC<{ children: any }> = ({ children }) => {
  const [user, setUser] = useState(null as any);
  const [db, setDb] = useState(null as any);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        console.log('user', {
          name: user.displayName,
          email: user.email,
          id: user.uid,
        });

        const x = firebase.firestore();
        (window as any).x = x;
        setDb(x);

        setUser({
          name: user.displayName,
          email: user.email,
          id: user.uid,
        });
      }
    });
  }, []);

  if(!user) {
    return  <Login />;
  }

  return (
    <AuthContext.Provider value={user}>
        <FirestoreContext.Provider value={db}>
            {children}
        </FirestoreContext.Provider>
    </AuthContext.Provider>
  );
};

const { Header, Content, Footer, Sider } = Layout;

class App extends Component {
  state = {
    collapsed: false,
  };

  onCollapse = (collapsed: boolean) => {
    this.setState({ collapsed });
  };
  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {
    return (
      <Auth>
          <Router>
            <Layout style={{ minHeight: '100vh' }}>
              <Sider collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
                <div className="logo" />
                <Menu theme="dark" defaultSelectedKeys={['1']} mode="inline">
                  <Menu.Item key="1">
                    <DesktopOutlined />
                    <span>Home</span>
                    <Link to="/" />
                  </Menu.Item>
                  <Menu.Item key="2">
                    <TableOutlined />
                    <span>Tables</span>
                    <Link to="/tables" />
                  </Menu.Item>
                  <Menu.Item key="3">
                    <BorderlessTableOutlined />
                    <span>Views</span>
                    <Link to="/views" />
                  </Menu.Item>
                </Menu>
              </Sider>
              <Layout>
                <Header style={{ background: '#fff', padding: 0, paddingLeft: 16 }}>
                  {this.state.collapsed ? (
                    <MenuUnfoldOutlined style={{ cursor: 'pointer' }} onClick={this.toggle} />
                  ) : (
                    <MenuFoldOutlined style={{ cursor: 'pointer' }} onClick={this.toggle} />
                  )}
                </Header>
                <Content style={{ margin: '24px 16px', padding: 24, background: '#fff', minHeight: 1280 }}>
                  <Route path="/" exact component={Dashboard} />
                  <Route path="/tables" exact component={DatabaseTableList} />
                  <Route path="/tables/:tableId" exact component={DatabaseTable} />
                  <Route path="/views" exact component={DatabaseTableViewList} />
                  <Route path="/views/:tableId" exact component={DatabaseView} />
                  <Route path="/tables/:tableId/test" component={EditableTable} />
                </Content>
                <Footer style={{ textAlign: 'center' }}>TheForge</Footer>
              </Layout>
            </Layout>
          </Router>
      </Auth>
    );
  }
}

export default App;
