import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import { Typography, Divider } from 'antd';

const {Title, Paragraph, Text} = Typography;


export const Dashboard = () => (<Typography>
    <Title>Guides</Title>
    <Divider></Divider>
    <Title level={2}>Documenting</Title>
    <Paragraph>You can document on view/table/field. <Text strong>Markdown supported!</Text></Paragraph>
    <Title level={2}>Flagging</Title>
    <Paragraph>There are 5 flags we can mark on a view/table/field.</Paragraph>
    <Paragraph><strong>Read</strong>: mean you <i>read</i> from the view/table/field in some of your code</Paragraph>
    <Paragraph><strong>Write</strong>: mean you <i>write</i> to the view/table/field in some of your code</Paragraph>
    <Paragraph><strong>Maintain</strong>: mean you <i>own</i> the view/table/field. The field is created for the system/app that you or your team own. You read from it and write to it</Paragraph>
    <Paragraph><strong>Doubt</strong>: mean you are <i>supspecting</i> that the view/table/field is not in use. But you are not 100% sure.</Paragraph>
    <Paragraph><strong>Trash</strong>: mean you are <i>sure</i> that the view/table/field is not in use by anyone.</Paragraph>
    <Link to={'/tables'}>
        <Button type="primary" size={'large'}>
            Tables
        </Button>
    </Link>
    <Link to={'/views'}>
        <Button type="primary" size={'large'} style={{ marginLeft: '10px' }}>
            Views
        </Button>
    </Link>
</Typography>);
